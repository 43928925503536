import axios from "axios";
import { getAccessToken, setAuthorizationToken } from "./session";

const api_error_code = {
  unauthorized: 401,
  accessDenied: 430,
  sessionExpired: 423,
  validationError: 400,
  emailNotVerified: 403,
};
const environment = "qa"
const $axios = axios.create({
  // baseURL: `https://infiniteview${environment}api.appskeeper.in/api/v1/user`,
  baseURL: `https://api.amma.social/api/v1/user`,
  timeout: 30000,
  headers: {
    Authorization: "Basic cmNjOnJjY0AxMjM=",
    api_key: "1234",
    device_type: "3",
    offset: 21,
    device_id: `${Date.now()}`,
    device_token: "win1234",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
const $axios1 = axios.create({
  responseType: "blob",
  // baseURL: `https://infiniteview${environment}api.appskeeper.in/api/v1/user`,
  baseURL: `https://api.amma.social/api/v1/user`,

  timeout: 30000,
  headers: {
    deviceType: "3",
    offset: "5:30",
    api_key: `1234`,
    "Access-Control-Allow-Origin": "*",
  },
});
// Only Usd For FAQ
const $axios2 = axios.create({
  baseURL: `https://infiniteview${environment}api.appskeeper.in/api/v1/admin`,
  // baseURL: `https://api.amma.social/api/v1/admin`,
  timeout: 30000,
  headers: {
    Authorization: "Basic cmNjOnJjY0AxMjM=",
    api_key: "1234",
    device_type: "3",
    offset: 21,
    device_id: "string",
    device_token: "string",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
const $axios3 = axios.create({
  baseURL: `https://graph.facebook.com/me/accounts?access_token=`,
  timeout: 30000,
  headers: {
    Authorization: "Basic cmNjOnJjY0AxMjM=",
    api_key: "1234",
    device_type: "3",
    offset: 21,
    device_id: "string",
    device_token: "string",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
const $axios4 = axios.create({
  timeout: 30000,
  // headers: {
  //   // 'Content-Type': 'application/x-www-form-urlencoded',
  //   // "Content-Type": "*/*",
  //   "Access-Control-Allow-Origin": "*",
  // },
});
const $axios5 = axios.create({
  baseURL: `https://graph.facebook.com/v13.0/oauth/access_token`,
  timeout: 30000,
  headers: {
    Authorization: "Basic cmNjOnJjY0AxMjM=",
    api_key: "1234",
    device_type: "3",
    offset: 21,
    device_id: "string",
    device_token: "string",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
$axios.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("access_token")
      ? localStorage.getItem("access_token")
      : sessionStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
$axios1.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("access_token")
      ? localStorage.getItem("access_token")
      : sessionStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
const baseUrlForVideo = "https://videorenderservicepp.amma.social/remotionLibrary/renderVideo";
const $axiosVideo = axios.create({
  // baseURL: `https://infiniteview${environment}microservice.appskeeper.in/remotionLibrary/renderVideo`,
  baseURL: baseUrlForVideo,
  timeout: 90000,
  headers: {
    Authorization: "Basic cmNjOnJjY0AxMjM=",
    api_key: "1234",
    device_type: "3",
    offset: 21,
    device_id: `${Date.now()}`,
    device_token: "win1234",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const renderImageUrl = `https://infiniteview${environment}microservice.appskeeper.in/remotionLibrary/renderVideo`;
const renderImagePreProdUrl = "https://videorenderservicepp.amma.social/remotionLibraryImage/renderImage"


const $axiosImage = axios.create({
  baseURL: renderImagePreProdUrl,
  timeout: 9000000,
  headers: {
    Authorization: "Basic cmNjOnJjY0AxMjM=",
    api_key: "1234",
    device_type: "3",
    offset: 21,
    device_id: `${Date.now()}`,
    device_token: "win1234",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
const constants = {
  api_error_code,
  axios: $axios,
  axios1: $axios1,
  axios2: $axios2,
  axios3: $axios3,
  axios4: $axios4,
  axios5: $axios5,
  axiosVideo: $axiosVideo,
  axiosImage: $axiosImage,
  getAccessToken,
  setAuthorizationToken,
  stateCityApiUrl: `https://api.amma.social/api/v1`,
  // apiUrl: `https://infiniteview${environment}api.appskeeper.in/api/v1/user`,
  apiAdminUrl: `https://infiniteview${environment}api.appskeeper.in/api/v1/admin`,
  apiUrl: `https://api.amma.social/api/v1/user`,
  // apiAdminUrl: `https://api.amma.social/api/v1/admin`,
  // apiAdminUrl: `https://api.amma.social/api/v1/admin`,
  videoUrl: `https://videorenderservicepp.amma.social/remotionLibrary`,
  apiFacebookUrl: `https://graph.facebook.com`,
  apiFacebookRefreshToken: `https://graph.facebook.com/v13.0/oauth/access_token`,
};
export default constants;

export const imageUrl = `https://infiniteview${environment}web.appskeeper.in/`;
