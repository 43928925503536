const endPoint = {
  login: "/login",
  forgotPassword: "/forget-password",
  resendOtp: "/resend-otp",
  verifyOTP: "/verify-otp",
  resetPassword: "/reset-password",
  signUp: "/signup",
  home: "/home",
  templateList: "/template-list",
  teamMember: "/member",
  company: "/company",
  socialSignIn: "/social-signin",
  changePassword: "/change-password",
  categoryList: "/category-list",
  addToFav: "/addToFavorite",
  logout: "/logout",
  templateDetails: "/template-details",
  favList: "/fav-list",
  deleteFavList: "/deleteFavouriteList",
  createFavList: "/createFavList",
  editFavList: "/edit-fav-list",
  favTemplateList: "/fav-template-list",
  customizableComponents: "/template/get-customizable-components",
  updateCustomizableComponents: "/template/update-customizable-components",
  videoUrl:
    "infiniteviewdevmicroservice.appskeeper.in/remotionLibrary/renderVideo",
  deleteFavTemplate: "/deleteFavouriteTemplate",
  recentSearch: "/recent-search",
  deleteUser: "/delete-user",
  faq: "/list-faqs",
  support: "/support",
  editProfile: "/edit-profile",
  profile: "/profile",
  myTemplates: "/myTemplates",
  subscriptionPlan: "/subscription-plans",
  planList: "/plan-list",
  payment: "/payment",
  scheduleFacebook: "/share-post-on-facebook",
  mySubscription: "/my-subscription",
  linkedinAuth: "/get-linkedin-auth",
  saveData: "/save-login-social-data",
  scheduleLinkedin: "/share-post-on-linkedin",
  linkedinProfile: "/linkedin-profile",
  paymentHistory: "/payment-history",
  paymentDetails: "/payment-details",
  checkSubscription: "/checkSubscription",
  deleteSchedulePost: "/delete-scheduled-post",
  editScheduledPost: "/edit-scheduled-post",
  scheduleInstagram: "/share-post-on-instagram",
  downladableUrl: "/get-downloadable-url",
  linkedAccount:"/get-social-acc-login-details",
  toggleSubscription:"/toggle-subscription",
  cms_details:"/cms_details",
  imageCustomizableData: "/brochure/get-customizable-components",
  saveEditImage: "/brochure/update-customizable-components",
  postImageLinkedin: "/multi-image-linkedin-post",
  scheduleFacebookImage: "/multi-image-facebook-post",
  schedulePostInstagramImages: "/multi-image-instagram-post",
  renderTemplate: "/render-template",
  requestDeleteOtp: "/request-delete-otp",
  deleteAccount: "delete-account"

};

export default endPoint;
